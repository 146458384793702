.blur-header {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: hsla(0, 0%, 100%, 0.2);
  padding: 24px;

  margin: 15px auto;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
